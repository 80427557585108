// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/ADMANAGER
// --------------------------------------------------

// VARIABLES
$base-overlay-white: rgba(255,255,255, 0.9);

// USED FROM _variables.scss


// USED FROM _layout.scss


// --------------------------------------------------

.ad {
	margin-bottom: $base-spacing-unit--lg;
	background: $base-overlay-white;
	text-align: center;
	padding: $base-spacing-unit;
	
	@include media-query(screen-lg) {
		margin-top: $base-spacing-unit;
		margin-bottom: $base-spacing-unit--xl;
	}
	
	.heading--3 {
		font-weight: 400;
		margin-bottom: 0;
	}
	
	.ad__btn {
		margin-top: $base-spacing-unit;
	}
}