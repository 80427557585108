// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-color-background: transparent;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/layout";

// --------------------------------------------------

// nav--mobile--active
html.html--fixed {
  overflow-y: hidden;
  position: fixed; // was position: relative
  width: 100%; 
}

[id="wrapper"] {
	background: $color-page-background;
}

[id="header"] {

	position: relative;
	left: 0;
	right: 0;
	
	border-top: 10px solid $color-brand;
	
	@include media-query(screen-offcanvas) {
		margin-top: 35px;
	}
	
	@include media-query(screen-lg) {
		border-top-width: 24px;
		margin-top: 60px;
	}
	
	
	.inside {
		@include wrapper-max(none);
		
		padding: 0;
		
	}
	
	> .inside {
    @include wrapper-max;
    @include padding-default;
    @extend .clearfix;
    
		position: static;
  }
}

[id="hero"] {
	position: static;
	padding-top: 84px;
}

// #main
.mod_article {
	padding-top:    $grid__gutter;
  padding-bottom: $grid__gutter;
  
  @include media-query(screen-lg) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-xl) {
    padding-top:    $base-spacing-unit--lg * 2;
    padding-bottom: $base-spacing-unit--lg * 2;
  }
  
  .inside {
	  > .last {
 	    margin-bottom: 0;
	  }
  }
}

// two column layout
.column--right {
	[id="container"] {
		@include wrapper-max;
		@include padding-default();
		
		padding-top:    $grid__gutter;
	  padding-bottom: $grid__gutter;
	  
	  @include media-query(screen-lg) {
	    padding-top:    $base-spacing-unit--lg;
	    padding-bottom: $base-spacing-unit--lg;
	  }
	  
	  @include media-query(screen-xl) {
	    padding-top:    $base-spacing-unit--lg * 2;
	    padding-bottom: $base-spacing-unit--lg * 2;
	  }
		
		> .inside {
			@include make-row();
		}
	}
	
	.mod_article {
		padding-left: 0;
		padding-right: 0;
	}
	
	.mod_article {
		padding-top: 0;
	}
	
	[id="main"] {
		@include padding-default;
		
		@include media-query(screen-md) {
			@include make-col(7);
		}
		
		@include media-query(screen-lg) {
			@include make-col(7);
		}
	}
	
	[id="right"] {
		@include padding-default;
		
		> .inside {
			@include make-row();
			@extend .row--flex;
			
						
			> * {
				@include padding-default;
				
				flex: 1 1 auto;
			}
		}
		
		@include media-query(screen-md) {
			@include make-col(4);		
		
			float: right;
		}
		
		@include media-query(screen-lg) {
			@include make-col(3);		
		
			float: right;
		}
		
		.col {
			@include media-query(screen-xs-max) {
				@include make-col(6);
			}

			@include media-query(screen-sm, screen-sm-max) {
				@include make-col(4);
			}
		}
		
		.col--map {
			@include media-query(screen-xs-max) {
				@include make-col(6);
			}
			
			@include media-query(screen-sm, screen-sm-max) {
				@include make-col(8);
			}
		}
	}
}

[id="footer"] {
	background: $color-gray;
	
	p,
	a {
		color: $color-text--inverted;
	}
}

