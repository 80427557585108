// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/GRID
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/grid";

// --------------------------------------------------


@include media-query(screen-xs-max) {

	.row {
		margin-left: -6px;
		margin-right: -6px;
	}

	@for $i from 1 through $grid__columns {
	  .col-xs-#{$i} { // [2][3]
	    padding-left: 6px;
	    padding-right: 6px;
	  }
	}
}

.row--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > * {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .align-bottom {
    margin-bottom: 0;
    margin-top: auto;
  }
  
  .align-middle {
	  justify-content: center;
  }
}

.row--reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.row--cards {
	margin: 0;
	
	@include media-query(screen-sm-max) {
		padding-bottom: $base-spacing-unit;
		width: auto;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		-ms-touch-action: auto;
	  /* no scrollbar for ie */
	  -ms-overflow-style: none;
	  
		flex-wrap: nowrap;
		scroll-snap-type: mandatory;
	}
	
	> * {
		flex-shrink: 0;
		scroll-snap-align: start;
	
		@include media-query(screen-sm) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.col--map {
	display: flex;
	flex: 1 1 auto;
}