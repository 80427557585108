// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/HERO
// --------------------------------------------------

// VARIABLES
$hero__color-background: $color-gray-dark;
$hero__color-text:       $color-text--inverted;
$hero__font-family:      $base-font-family;
$hero__font-weight:      700;

// USED FROM _variables.scss
// $base-spacing-unit, --lg

// USED FROM _layout.scss
/// $header-height

// --------------------------------------------------

// hero
// [1] every .inside container has position relative by contao default
.mod_article.hero {
  @include wrapper-max(none);

  position: static;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 0;
  top: 0;
  
  @include media-query(screen-offcanvas) {
  	min-height: 200px;
  }
  
  @include media-query(screen-lg) {
  	min-height: 430px;
  }
  

  
    
  > .inside {
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
    position: static; // [1]
    min-height: inherit;
    overflow: hidden;
    z-index: 1001;
    
    > *:not(.hero__image) {
	    position: relative;
	    z-index: 1;
    }
  }
}

// [1] IE10 calculating wrong widths, when max-width isn't set
// [2] make sure sizing and text-aligning is correct, when headline and paragraph are shorter than 2 lines
.hero__text {
  margin-top: $base-spacing-unit * 2.75;    
  position: relative;
  text-align: center;
  color: $color-text--inverted;
  background: rgba(#787773, 0.9);
  font-weight: $hero__font-weight;
	font-size: 1.75rem;
	line-height: 1.5;
	padding: $base-spacing-unit--xs;
	text-transform: uppercase;
	
	@include media-query(screen-lg) {
		font-size: 1.5rem;
	}
  
  .ie10 & {
	  max-width: 100%; // [1]
  }
  
  .hero--full & {
	  background: transparent;
	  margin-top: $base-spacing-unit--xl;
	  margin-bottom: $base-spacing-unit--xl;
	  
	  @include media-query(screen-lg) {
			font-size: 2.875rem;
			
		}
  }
}

.hero__image .image_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //z-index: 0;
  
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
