// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/CARD
// --------------------------------------------------

// VARIABLES
$card-shadow: rgba(0,0,0,0.35);
$card-highlight-bg: $color-gray-lighter;

// USED FROM _variables.scss
// $grid__gutter

// --------------------------------------------------


.card {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	//box-shadow: 0 0 4px $card-shadow;
	border: 1px solid $card-highlight-bg;
	margin-bottom: $grid__gutter;
	
	.image_container {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		overflow: hidden;
	}
	
	a {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		align-content: center;
		color: inherit;
		overflow: hidden;
	}
	
	img {
		flex: 0 0 auto;
		overflow: hidden;
	}
	
	.caption {
		font-weight: 700;
		text-align: center;
		margin-top: $base-spacing-unit--xs;
		margin-bottom: $base-spacing-unit--xs;
		margin-top: auto;
		margin-bottom: auto;
		padding: $base-spacing-unit--sm $base-spacing-unit--sm;
		white-space: normal;
		overflow: hidden;
	}
}

.card--module {
	font-size: 0.875rem;
	box-shadow: none;
	border: 1px solid $card-highlight-bg;
}

.card--highlight {
	font-size: 0.875rem;
	box-shadow: none;
	border: 0;
	background: $card-highlight-bg;
	padding: $base-spacing-unit;
}