// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// PUT YOUR OWN VARIABLES HERE
// --------------------------------------------------

$color-brand:					#F9B403; // yellow
$color-gray:					#787773; // gray
$color-gray-lighter:	#EAE9E8;


$color-text: 				#787773; 

$base-box-shadow:		0 0 10px rgba(0,0,0,0.35);
$base-overlay-background:  rgba(0,0,0,0.8);


// Buttons
$color-btn-dark: #A5870B;

// Base Font Settings

$base-font-size--xxs:      0.875rem;


$base-font-family:        "Source Sans Pro", "Helvetica Neue", sans-serif;
$base-line-height:        1.4;



// Base Border Settings
$base-border-radius:          10px;
$base-border-width:           1px;
$base-border-color:           $color-gray;

$base-border:                 $base-border-width solid $base-border-color !default;

// --------------------------------------------------

@import "../../../nutshell/scss/variables";

// --------------------------------------------------
