// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/NAVIGATION
// --------------------------------------------------

// VARIABLES
  $color-navigation-main--active:   	$color-gray;
  $nav--main__item-padding:         	$base-spacing-unit;
  $nav--main__item-padding--sm:     	$base-spacing-unit--xs;
  $nav--main-font:                  	$base-font-family;
  $nav--main-font-weight:           	700;
  
  $submenu-background:								$base-overlay-background;
  
  $nav-mobile-color-background:       $base-overlay-background;
  
  $menu-icon-width:										1.75rem;
  $menu-icon-stroke:									3px;
  $header-height:											4rem;
  

// USED FROM _variables.scss
// $color-text--inverted
// $base-spacing-unit, --xs, --sm
// $base-border
// $base-font-family-sans-serif

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// --------------------------------------------------

@import "../../../../nutshell/scss/components/navs";

// --------------------------------------------------

// Main Navigation

// [1] IE9 needs this to make the whole anchor clickable
// [2] 9999px make sure, the max-height is bigger than the actual submenu height, percentage will reference the header height
.nav--main {
  line-height: 1em;
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;
  
  a,
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: $nav--main__item-padding;
    display: block;
    font-weight: 700;
    text-decoration: none;
    background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // [1]



  }
  
  li.last {
	  a,
	  span.active,
	  span.trail,
	  strong.active,
	  strong.trail {
		  padding-right: 0;
	  }
  }
  
  a {
    color: inherit;
  }
  
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    color: $color-navigation-main--active;
  }
}


// [1] transition for mobile navigation fade in background
// [2] transition for mobile navigation item moving
.nav--mobile {
  max-height: 0;
  visibility: hidden;
  transition: max-height 0s 3s, background 0.3s; // [1]
  background: transparent; // [1]
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: -1;
  visibility: visible;
  font-size: 1.5rem;
  text-shadow: 0.025rem 0.025rem 2rem $nav-mobile-color-background;
  
  > ul {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  
  > ul > li {
  transition: transform 0.3s, opacity 0.3s; // [2]
  opacity: 0; // [2]
  
    @include media-query(screen-offcanvas) {
      opacity: 1;
    }
  }
  
  a {
    color: $color-text--inverted;
  }
  
	> ul {
		max-height: 100%;
    padding-top: $header-height;
    padding-bottom: $header-height;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: touch;
    -ms-overflow-style: none;
	}

	.ie9 & {
	  > ul {
			overflow-y: auto; // [1]
	  }
  }
}


.nav--mobile--active {
  max-height: 100%;
  transition: max-height 0s, background 0.3s; // [1]
  background: $nav-mobile-color-background;
  z-index: 1004;

  >ul > li {
    opacity: 1;
  }
}

// button to display mobile navigation
.nav-toggler {
	float: right;
  font-family: sans-serif;
  position: relative;
  z-index: 1006;
  margin-top: 14px;
  
  @include media-query(screen-lg) {
  	margin-top: 34px;
  }
  
  
  .html--fixed & {

  }
}

.nav-toggler__button {  
  @extend %button;
  @extend .btn--primary;
  
  padding: ($base-spacing-unit * 0.875) $base-spacing-unit*1.5 ($base-spacing-unit * 0.875) $base-spacing-unit;
  
  border: 0;
  
  &:focus {
	  outline: none;
  }
  
  .html--fixed & {
	  color: $color-text--inverted;
  }
}

.menu-icon {
	position: relative;
	width: $menu-icon-width;
	height: $base-line-height * 1rem;
	display: inline-block;
	vertical-align: top;
	margin-left: $base-spacing-unit--sm;
	margin-right: $base-spacing-unit;
	
	@include media-query(screen-offcanvas) {
		width: $menu-icon-width*0.75;
	}
	
	@include media-query(screen-lg) {
		width: $menu-icon-width;
	}
}

.menu__label {
}

.menu-icon__inner {
	width: 100%;
	background-color: currentcolor;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: $menu-icon-stroke - 4px;
  height: $menu-icon-stroke;
  transition: transform 0.15s ease;
  
  @include media-query(screen-lg) {
  	height: $menu-icon-stroke*2;
  	margin-top: $menu-icon-stroke - 2px;
  }
  
  @include media-query(screen-xl) {
  	margin-top: $menu-icon-stroke;
  }

	&:after, 
	&:before {
	  background-color: currentcolor;
	  position: absolute;
	  display: block;
		content: "";
    width: 100%;
		height: $menu-icon-stroke;
    transition: transform 0.15s ease, opacity 0.15s;
    
    @include media-query(screen-lg) {
	  	height: $menu-icon-stroke*2;
	  }
	}
	
	&:before {
		top: -5px;
		
		@include media-query(screen-lg) {
			top: -9px;
		}
	}
	
	&:after {
		bottom: -5px;
		
		@include media-query(screen-lg) {
	  	bottom: -9px;
	  }
	}
	
	.html--fixed & {
		transform: rotate(45deg);
		
		&:before {
			opacity: 0;
		}
		
		&:after {
			transform: rotate(-90deg);
			bottom: 0;
		}
	}
}

.nav--meta {
	
	ul {
		text-align: center;
	}
	
	li {
		display: inline-block;
		
		&:after {
			content: '|';
			color: $color-text--inverted;
			font-weight: 700;
		}
		
		&.last {
			&:after {
				display: none;
			}
		}
	}
	
	a,
	strong.active {
		padding: $base-spacing-unit;
		color: $color-text--inverted;
		display: inline-block;
		font-weight: 700;
	}
	
	strong.active {
		color: rgba($color-text--inverted, 0.7);
	}
}
