// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.map {
	
}

.map--small {
	display: flex;
	flex: 1 1 auto;
	height: 100%;
	
	.column--right & {
		position: relative;
		height: 300px;
	}
}
