// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/PAGE
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/page";

// --------------------------------------------------

html {
	@include media-query(screen-xs-max) {
    font-size: ($base-font-size--xxs / 1rem) * 1em; 
  }
}
