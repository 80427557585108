// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$headings__font-family: $base-font-family;
$headings__font-weight: 700;

$headlines: (
  h1: (
    sizes: (
      xs: 2.0rem,
      sm: 2.0rem,
      md: 2.25rem,
      lg: 2.25rem,
      xl: 2.25rem
    ),
    line-height: 1.55, 
    margin-top: 2rem,
    margin-bottom: 1rem
  ),
  h2: (
    sizes: (
      xs: 1.75rem,
      sm: 1.75rem,
      md: 1.5rem,
      lg: 2.000rem,
      xl: 2.2750rem // ce_text „Hier könnte ein Akkordeon-Element kommen“
    ),
    line-height: 1.2, // text--image
    margin-top: 2rem,
    margin-bottom: 1.5rem
  ),
  h3: (
    sizes: (
      xs: 1.375rem,
      sm: 1.375rem,
      md: 1.125rem,
      lg: 1.125rem,
      xl: 1.55rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 0.5rem
  ),
  h4: (
    sizes: (
      xs: 1.125rem,
      sm: 1.125rem,
      md: 1.250rem,
      lg: 1.250rem,
      xl: 1.250rem
    ),
    line-height: 1,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
  h5: (
    sizes: (
      xs: 1rem,
      sm: 1rem,
      md: 1rem,
      lg: 1rem,
      xl: 1rem
    ),
    line-height: inherit,
    margin-top: 1.5rem,
    margin-bottom: 0rem
  ),
  h6: (
    sizes: (
      xs: 0.875rem,
      sm: 0.875rem,
      md: 0.875rem,
      lg: 0.875rem,
      xl: 0.875rem
    ),
    line-height: 1,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
);

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/type";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

// HEADINGS
#{headings()} { 
  
}

.ce_headline {

}

// heading sizes 1-6
// [1] make sure, that only the first headline inside an element or module 
// is styled this way 
@for $i from 1 to 7 {
	.heading--#{$i} {
		&.ce_headline {
	  	@extend %_h#{$i};
	  }
	  
	  > #{headings()} { // [1]
			&:first-child {
				@extend %_h#{$i};
			}
		}
		
		.ce_text &,
		.info & {
			@extend %h#{$i};
		}
	}
}

.heading--center {
  text-align: center;
}


// TEXT
.text--primary {
  color: $color-brand;
}

.text--secondary {
  color: $color-brand;
}

.text--attention {
  font-size: 1.25em;
  font-weight: 300;
  letter-spacing: 0.01875rem;
  text-align: center;
}

.text--slogan {

  font-style: italic;
  font-size: 1.25em;
  font-weight: 700;
  text-align: center;
}

.ce_text a {
  color: inherit;
}

p,
ul,
ol {
	font-weight: 300;
}

// Lists
.ce_text ul {
  margin-left: 0;
  padding-left: $base-spacing-unit*1.25;
}
