// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/ACCORDION
// --------------------------------------------------

// VARIABLES
$accordion-padding:       $base-spacing-unit;
$accordion-background:		#E5E5E5;


$toggler-font-size: 			1.125rem;
$toggler-background: 			linear-gradient(#EDEDED 0%, #B4B4B4 100%);
$toggler-color: 					$color-gray;
$toggler-spacing:         0;
$toggler-border: 					0;
$toggler-icon-size:       1.25 !default;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/accordion";

// --------------------------------------------------

.ce_accordion {
	.toggler {
		font-weight: 700;
	}
	
	// [1] define size for plus/cross
  .ui-accordion-header-icon {
    transition: transform 0.3s;
    float: right;
    position: relative;
    top: 6px;
    display: inline;
    vertical-align: bottom;
    transform: scale($toggler-icon-size); // [1]
    transform-origin: center;
    
    &:before {
      content: "";

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.778 20.355'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 { fill: %23fff; } %3C/style%3E%3C/defs%3E%3Cpath id='Pfeil_nach_unten' data-name='Pfeil nach unten' class='cls-1' d='M0,0H26.778L13.169,20.355Z' transform='translate(0)'/%3E%3C/svg%3E");

    background-position: center center;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    display: block;
    }
  }
  
  .ui-icon-triangle-1-s {
    transform: rotate(180deg) scale($toggler-icon-size);
  }
  
  .accordion {
	  font-weight: 300;
  }
  
  .ce_text {
	  @include make-row();
	  
	  .image_container {
		  @include make-col(3);
		}
		  
		.text_container {
		  @include make-col(12);
	  }
	  
	  .image_container + .text_container {
		  @include make-col(9);
	  }
  }
}
