// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/LINKS
// --------------------------------------------------

// VARIABLES
$button-bg: linear-gradient($color-btn, $color-btn-dark);
$button-bg-hover: linear-gradient(darken($color-btn,2%), darken($color-btn-dark,2%));
$button-font-weight: 700;

$button-shadow:		0 0 4px rgba(0,0,0,0.35);

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/links";

// --------------------------------------------------

%button {
	border: 0;
	box-shadow: $button-shadow;
	
	@include media-query(screen-lg) {
		$button-spacing: ($base-spacing-unit * 0.875) ($base-spacing-unit--lg * 1.5);
		
		padding: $button-spacing;
		font-size: 1.5rem;
	}
}
