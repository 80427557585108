// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.logo {
	position: relative;
	float: left;
	
	background: $color-page-background;
	padding: $base-spacing-unit--sm*1.375 $base-spacing-unit--sm*1.375;
	box-shadow: $base-box-shadow;
	z-index: 1004;
	margin-top: -10px;
	max-width: 7.5rem;
	
	@include media-query(screen-offcanvas) {
		margin-top: -45px;
		max-width: none;
		max-width: 9.5rem;
		padding: $base-spacing-unit $base-spacing-unit;
	}
	
	@include media-query(screen-lg) {
		margin-top: -84px;
		max-width: none;
		padding: $base-spacing-unit * 1.75 $base-spacing-unit--lg;
	}
}